<template>
  <ul class="lista-links">
    <li class="link" v-for="i in items" :key="i.key">
      <a :id="i.key" :href="i.url" target="_blank">
        <template v-if="i.tipo === 'pdf'">
          <img class="logoTipoArchivo" src="../assets/logos/pdf.svg" alt="Logo PDF">
        </template>
        <template v-else-if="i.tipo === 'doc'">
          <img class="logoTipoArchivo" src="../assets/logos/doc.svg" alt="Logo Word">
        </template>
        <template v-else-if="i.tipo === 'xls'">
          <img class="logoTipoArchivo" src="../assets/logos/xls.svg" alt="Logo Excel">
        </template>
        <template v-else-if="i.tipo === 'audio'">
          <img class="logoTipoArchivo" src="../assets/logos/mp3.svg" alt="Logo Audio">
        </template>
        <template v-else>
          <img class="logoTipoArchivo" src="../assets/logos/archivo.svg" alt="Logo Archivo">
        </template>
      </a>
      <b-tooltip v-if="i.nombrearchivo" :target="i.key" triggers="hover">
        {{ i.nombrearchivo }}
      </b-tooltip>
    </li>
  </ul>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "PubUrlsLista",
  props: ["seccion", "subseccion", "publicacion", "documento"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("documentos")
        .doc(this.documento)
        .collection("urls"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("tipo").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          tipo: doc.data().tipo,
          url: doc.data().url,
          nombrearchivo: doc.data().nombrearchivo,
        });
      });
      //console.log(this.items)
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";
  .lista-links {
    padding: 0;
    list-style: none;
    display: flex;
    margin-bottom: 0.5rem;
    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }
    &:hover {
      background: none;
    }
  }
  .link {
    a {
      padding: 0;
    }
    .logoTipoArchivo {
      width: 1.5rem;
      margin: 0 0.2rem;
    }
  }
  
</style>
