<template>
  <div class="container-fichas" v-if="images!=0">
    <div class="container-foto" v-for="(image, i) in images" :key="i">
      <a :href="image.urlLink" target="_blank">
        <img 
          class="image" 
          :src="image.url" 
          :alt="image.descripcion" 
          @click="index = i" 
        >
      </a>
      <span v-if="image.descripcion">{{ image.descripcion }}</span>
    </div>
  </div>
</template>

<script>
import firebase from "../firebase";

export default {
  name: "PubLinksFotoLista",
  props: ["seccion", "subseccion", "publicacion"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("linksfoto"),
      images: {},
      index: null
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.images = [];
      querySnapshot.forEach((doc) => {
        this.images.push({
          key: doc.id,
          url: doc.data().url,
          urlLink: doc.data().urlLink,
          orden: doc.data().orden,
          descripcion: doc.data().descripcion
        });
      });
      //console.log(this.fotos)
      //console.log("Esto es la Imagen: " + this.images)
    });
  }
};
</script>

<style lang="scss" scoped>

  @import "../scss/abstracts/variables";
  .container-foto {
    display: inline-grid;
    width: 250px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      margin: 1rem;
    }
  }
  .image {
    height: 180px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid $color-text;
    @include media-breakpoint-up(md) {
      height: 200px;
    }

    &:hover{
      border: 2px solid $color-01;
    }
    //border-radius: 5px;
  }
  span {
    font-size: 0.9rem;
    font-weight: 300;
  }

</style>