<template>
  <div class="container-subsecciones">
    <p v-if="items.length == 0" class="text-center">
      Aún no hay subsecciones
    </p>
    <template v-else>
      <ul class="accordion ul-accordion" role="tablist">
        <li no-body v-for="i in items" :key="i.key">
          <div header-tag="header" class="header-accordion" role="tab">
            <h2 class="titulo-subseccion-accordion" block v-b-toggle="'accordion-' + i.key">
              <b-icon class="icono-down" icon="arrow-down-circle-fill" aria-hidden="true"></b-icon>
              {{ i.titulo }}
            </h2>
          </div>
          <b-collapse :id="'accordion-' + i.key" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <PubPublicacionLista :seccion="seccion" :subseccion="i.key" />
            </b-card-body>
          </b-collapse>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import firebase from "../firebase";
import PubPublicacionLista from "./PubPublicacionLista.vue"

export default {
  name: "PubSubseccionLista",
  props: ["seccion"],
  components: {
    PubPublicacionLista,
  },
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          titulo: doc.data().titulo,
          orden: doc.data().orden,
        });
      });
      //console.log(this.items)
    });
  },
};
</script>

<style lang="scss" scoped>
  @import "../scss/abstracts/variables";
  .ul-accordion {
    list-style: none;
  }
  .container-subsecciones {
    padding: 0.25rem;
    margin-bottom: 2rem;
    text-align: left;
    @include media-breakpoint-up(md) {
      padding: 1rem;
    }
  }
  .header-accordion {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    &:hover {
      background: #eee;
    }
  }
  .icono-down {
    color: $color-gris; 
    margin-left: -1.25rem;
    margin-right: 0.1rem;
    @include media-breakpoint-up(md) {
      font-size: 1.1rem;
      margin-right: 0.4rem;
    }
  }
  .titulo-subseccion-accordion {
    font-size: 1.1rem;
    font-family: $font-01;
    font-weight: 300;
    margin: 0 0 0 1.7rem;

    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
      margin: 0 0 0 1.75rem;
    }
  }
</style>
